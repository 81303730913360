import Typography from '@mui/material/Typography'
import '../styles/index.css'
import { useSelector } from 'react-redux'
import { RootState } from '../app/store'
import { selectAllVehicles } from '../redux_features/vehicles/vehicleSlice'
import { selectAllLiveGeolocations } from '../redux_features/geolocations/geolocationSlice'
import RightDrawer from './RightDrawer'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import SpeedIcon from '@mui/icons-material/Speed'
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation'
import TimeToLeaveIcon from '@mui/icons-material/TimeToLeave'
import { styled } from '@mui/material/styles'

const InfoCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
}))

const IconWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
  '& > svg': {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
}))

export type vehicInfoprops = {
  deviceid: string
  close?: () => void
}

export interface sharedVehicle {
  name: string
  vehicle: { id: string | undefined }
  startDate: Date
  endDate: Date
}

export const VehicleInfo = (props: vehicInfoprops) => {
  const speedUnits = useSelector(
    (state: RootState) =>
      state.session.userdata.OrganizationUser.primaryOrganizationUser
        .userSettings?.speedUnit,
  )

  const geolocations = useSelector(
    (state: RootState) => state.geolocations.geolocations,
  )
  let formattedSpeed = '0'
  const speed = geolocations.find(
    (geo) => geo.deviceId === props.deviceid,
  )?.speed
  if (speed) {
    formattedSpeed =
      speedUnits === 'mph' ? (speed * 2.23694).toFixed(1) : speed.toFixed(1)
  }

  return (
    <RightDrawer anchor="right" close={props.close}>
      <Typography variant="h5" gutterBottom>
        Vehicle Info
      </Typography>

      <InfoCard>
        <CardContent>
          <IconWrapper>
            <TimeToLeaveIcon />
            <Typography variant="h6">Vehicle Details</Typography>
          </IconWrapper>
          <Typography variant="body1">Year: N/A</Typography>
          <Typography variant="body1">Odometer: N/A</Typography>
        </CardContent>
      </InfoCard>

      <InfoCard>
        <CardContent>
          <IconWrapper>
            <SpeedIcon />
            <Typography variant="h6">Speed</Typography>
          </IconWrapper>
          <Typography variant="h4">
            {formattedSpeed}{' '}
            <Typography variant="caption">{speedUnits}</Typography>
          </Typography>
        </CardContent>
      </InfoCard>

      <InfoCard>
        <CardContent>
          <IconWrapper>
            <LocalGasStationIcon />
            <Typography variant="h6">Fuel Level</Typography>
          </IconWrapper>
          <Typography variant="body1">N/A</Typography>
        </CardContent>
      </InfoCard>
    </RightDrawer>
  )
}
