import { useState } from 'react'
import '../styles/VehicleListItem.css'

import { useSelector } from 'react-redux'
import { RootState } from '../app/store'

import { LastKnownData } from '../api/types'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined'

import { ShareLocationSettings } from './ShareLocationSettings'

import NearMeIcon from '@mui/icons-material/NearMe'
import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  Icon,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'

import LocationOnIcon from '@mui/icons-material/LocationOn'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import VpnKeyIcon from '@mui/icons-material/VpnKey'
import PersonIcon from '@mui/icons-material/Person'
import React from 'react'
import useIsMobile from '../customHooks/useIsMobile'
import { MapControllerState } from './NewMapController/mapTypes'

type VehicleItemPropsDev = {
  //TODO get rid of redundant data and pass the major info
  zoomin: (dev: string) => void
  clickHistory: (device: string) => void
  clickInfo: (device: string) => void
  geolocation: LastKnownData
  state: MapControllerState
  showRelativeTime: boolean
  setShowRelativeTime: (show: boolean) => void
  isSelected: boolean
}

type GMMItem = {
  icon: string
  label: string
  callback: () => void
}

type GMMArray = {
  menurArr: GMMItem[]
}

const GenericMoreMenu = (props: GMMArray) => {
  const [open, setOpen] = useState(null)
  const handleOpenMenu = (event: any) => {
    setOpen(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setOpen(null)
  }

  return (
    <>
      <Tooltip title="Options">
        <Button sx={{ marginTop: '-5px' }} onClick={handleOpenMenu}>
          <MoreVertOutlinedIcon />
        </Button>
      </Tooltip>
      {open && (
        <Popover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          PaperProps={{
            sx: {
              p: 1,
              width: 200,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }}
        >
          <MenuList>
            {props.menurArr.map((item, index) => {
              return (
                <MenuItem
                  key={index}
                  onClick={item.callback}
                  value={item.label}
                >
                  <ListItemText>{item.label}</ListItemText>
                  <Icon>{item.icon}</Icon>
                </MenuItem>
              )
            })}
          </MenuList>

          {/* <MenuItem sx={{ color: 'error.main' }}>View History</MenuItem> */}
        </Popover>
      )}
    </>
  )
}

const getTimeAgo = (timestamp: string): string => {
  const now = new Date()
  const past = new Date(timestamp)
  const diffMs = now.getTime() - past.getTime()

  const minutes = Math.floor(diffMs / (1000 * 60))
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)

  if (days > 0) return `${days}d ago`
  if (hours > 0) return `${hours}h ago`
  if (minutes > 0) return `${minutes}m ago`
  return 'Just now'
}

const VehicleListItem = (props: VehicleItemPropsDev) => {
  const [openShareWindow, setOpenShareWindow] = useState(false)
  const [ignitionOn] = useState(true) //TODO dummy data this should come in device data
  const isMobile = useIsMobile()

  const livegeolocations = useSelector(
    (state: RootState) => state.geolocations.geolocations,
  )

  const VehicleMenu: GMMItem[] = [
    {
      icon: 'info',
      label: 'vehicle info',
      callback: () => {
        props.clickInfo(props.geolocation.deviceId)

        // navigate('/tracking', { replace: true })
      },
    },

    {
      icon: 'history',
      label: 'tracking history',
      callback: () => {
        props.clickHistory(props.geolocation.deviceId)
      },
    },
    {
      icon: 'share',
      label: 'share',
      callback: () => {
        setOpenShareWindow(true)
      },
    },
  ]

  // TODO review this DO NOT ERASE

  //#TODO fix date format
  const timestampIso = livegeolocations.find((item, index) => {
    return item.deviceId === props.geolocation.deviceId
  })?.timestamp

  return (
    <Card
      sx={{
        mb: 1,
        backgroundColor: props.isSelected ? '#e3f2fd' : 'inherit', // Light blue background when selected
        transition: 'background-color 0.2s ease', // Smooth transition effect
        '&:hover': {
          backgroundColor: props.isSelected ? '#e3f2fd' : '#f5f5f5', // Keep selected color on hover, or light grey for unselected
        },
      }}
    >
      <CardContent>
        <Box onClick={() => props.zoomin(props.geolocation.deviceId)}>
          <Stack spacing={1}>
            {/* Header */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <NearMeIcon fontSize="small" />
                <Typography variant="h6">
                  {props.geolocation?.vehicleName || 'Unassigned Vehicle'}
                </Typography>
              </Stack>
              <GenericMoreMenu menurArr={VehicleMenu} />
            </Box>

            {/* Driver Info */}
            <Stack direction="row" spacing={1} alignItems="center">
              <PersonIcon fontSize="small" color="action" />
              <Typography variant="body2">
                {props.geolocation?.driverName || 'Unassigned Driver'}
              </Typography>
            </Stack>

            {/* Location */}
            <Stack direction="row" spacing={1} alignItems="center">
              <LocationOnIcon fontSize="small" color="action" />
              <Typography variant="body2">
                {props.geolocation?.address || 'No Address'}
              </Typography>
            </Stack>

            {/* Status Indicators */}
            <Stack spacing={1}>
              <Stack direction="row" spacing={1} alignItems="center">
                <DirectionsCarIcon
                  fontSize="small"
                  color={props.geolocation.moving ? 'success' : 'action'}
                />
                <Typography variant="caption">
                  {props.geolocation.moving ? 'In Motion' : 'Stopped'}
                </Typography>
              </Stack>

              <Stack direction="row" spacing={1} alignItems="center">
                <VpnKeyIcon
                  fontSize="small"
                  color={ignitionOn ? 'success' : 'action'}
                />
                <Typography variant="caption">
                  {ignitionOn ? 'Ignition On' : 'Ignition Off'}
                </Typography>
              </Stack>

              <Stack direction="row" spacing={1} alignItems="center">
                <AccessTimeIcon
                  fontSize="small"
                  color="action"
                  onClick={() =>
                    props.setShowRelativeTime(!props.showRelativeTime)
                  }
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      transform: 'scale(1.1)', // Slight grow effect on hover
                      color: 'primary.main', // Change color on hover
                      transition: 'all 0.2s', // Smooth transition
                    },
                    // Optional: add a subtle shadow
                    filter: 'drop-shadow(1px 1px 1px rgba(0,0,0,0.2))',
                  }}
                />
                <Typography variant="caption">
                  {props.showRelativeTime
                    ? getTimeAgo(timestampIso || '')
                    : new Date(timestampIso || '').toLocaleString()}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Box>

        <ShareLocationSettings
          open={openShareWindow}
          handleClose={() => setOpenShareWindow(false)}
          vehicleId={props.geolocation.vehicleId}
        />
        {isMobile && props.state.rightDrawerMode === 'history' && (
          <Accordion>
            <AccordionSummary> History Here</AccordionSummary>
          </Accordion>
        )}
      </CardContent>
    </Card>
  )
}

export default VehicleListItem
