import './styles/App.css'
import { ReactNode, useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import TestPage from './components/TestPage'
import Unauthorized from './components/Unauthorized'

import RequireCogAuth from './redux_features/session/RequireCogAuth'
import SignUp from './redux_features/session/SignUp'
import OrganizationEntities from './components/OrganizationEntities'

import SessionLogin from './redux_features/session/SessionLogin'

import Help from './components/Help'
import UserAccount from './components/user/UserAccountPage'

import { createTheme, ThemeProvider } from '@mui/material/styles'

import UserPreferences from './components/user/UserPreferences'

import SecurityPage from './components/SecurityPage'
import PaymentsPage from './components/PaymentsPage'

import ForgotPassword from './redux_features/session/ForgotPassword'
import { createContext } from 'react'

import DriversList from './redux_features/drivers/DriversList'
import DevicesList from './redux_features/devices/DevicesList'
import CompleteRegistration from './redux_features/session/CompleteRegistration'
import { VehiclesList } from './redux_features/vehicles/VehicleList'
import NotFound from './components/Notfound'

import SharedLocationMap from './components/SharedLocationMap'
import ShareLocationList from './components/ShareLocationList'

import IdleTimeout from './components/IdleTimeout'
import Dashboard from './components/dashboard/Dashboard'

import Alerts from './components/alerts/Alerts'
import Reports from './components/reports/Reports'
import GeofencesList from './redux_features/geofences/GeofencesList'

import OrganizationUsersList from './redux_features/users/OrganizationUsersList'
import GeoAccessList from './components/geofences/GeoAccessList'
import MapJSContainer from './components/NewMapController/MapJSContainer'

import Layout from '../src/components/Layout/Layout'
import Landing from './components/LandingPage/Landing'

import useAuthStatus from './customHooks/useAuthStatus'
import WSocket2 from './components/WebSocket/WSocket2'
import Dispatching from './components/dispatching/Dispatching'
export const DrawerContext = createContext(false)

const App = () => {
  const [pollingState, setPollingState] = useState(false)

  const [drawerStatus, setInactive] = useState(false)

  const [width, setWindowWidth] = useState(window.innerWidth)
  const { isLoggedIn } = useAuthStatus()

  const theme = createTheme({
    palette: {
      primary: {
        main: '#614CE1',
      },
    },
  })

  useEffect(() => {
    window.addEventListener('resize', updateDimensions)

    return () => {
      window.removeEventListener('resize', updateDimensions)
    }
  }, [])

  const updateDimensions = () => {
    const width = window.innerWidth

    setWindowWidth(width)
  }

  const deactivateIt = () => {
    setInactive(!drawerStatus)
  }

  const SimpleLayout = ({ children }: { children: ReactNode }) => {
    return <div>{children}</div>
  }

  const setPolling = (value: boolean) => {
    console.log(' setting polling to ', value)
    setPollingState(value)
  }
  console.log('rendering App')
  return (
    <ThemeProvider theme={theme}>
      <DrawerContext.Provider value={drawerStatus}>
        <div
          style={{
            position: 'fixed',
            width: width,
            transition: '0.5s',
            overflow: 'scroll',
            height: '100vh',
            padding: 0,
          }}
        >
          <BrowserRouter>
            <Layout isLoggedIn={isLoggedIn}>
              <IdleTimeout />
              <Routes>
                <Route
                  path="/sharedlocation/:shareid"
                  element={<SharedLocationMap />}
                />
                <Route path="/" element={<Landing />} />
                <Route
                  path="/sharedlocations/"
                  element={<ShareLocationList />}
                />
                <Route path="*" element={<NotFound />} />
                <Route
                  path="/login"
                  element={<SessionLogin compactView={true} />}
                />{' '}
                <Route path="/unauthorized" element={<Unauthorized />} />
                <Route path="/signup" element={<SignUp />} />
                <Route
                  path="/forgotpassword"
                  element={
                    <SimpleLayout>
                      <ForgotPassword />
                    </SimpleLayout>
                  }
                />
                <Route
                  element={
                    <RequireCogAuth
                      drawerStatus={drawerStatus}
                      roleRequired="admin"
                    />
                  }
                >
                  <Route path="/testpage" element={<TestPage />} />
                  {/* <Route path="/dashboard" element={<DashboardPage />} /> */}
                  <Route
                    path="/dashboard"
                    element={<Dashboard />} //
                  />
                  <Route
                    path="/alerts"
                    element={<Alerts />} //
                  />
                  <Route
                    path="/reports"
                    element={<Reports />} //
                  />

                  <Route
                    path="/live-tracking"
                    // element={<MainMapContainer polling={pollingState} />} //used if the websocket goes down
                    element={<MapJSContainer />} //
                  />
                  <Route
                    path="/dispatching"
                    element={<Dispatching />} //
                  />
                  <Route
                    path="/geofences"
                    element={<GeofencesList />} //
                  />

                  <Route path="/accesslist" element={<GeoAccessList />} />
                  <Route path="/settings" element={<UserPreferences />} />
                  <Route path="/settings/vehicles" element={<VehiclesList />} />
                  <Route path="/settings/drivers" element={<DriversList />} />
                  <Route path="/settings/devices" element={<DevicesList />} />
                  <Route
                    path="/settings/users"
                    element={<OrganizationUsersList />}
                  />

                  {/* <Route path="/viewUser/:userId" element={<SingleUser />} /> */}
                  <Route path="/assets" element={<OrganizationEntities />} />
                  <Route path="/account" element={<UserAccount />} />
                  <Route path="/account/security" element={<SecurityPage />} />
                  <Route path="/account/payments" element={<PaymentsPage />} />

                  <Route
                    path="/complete-registration"
                    element={<CompleteRegistration />}
                  />
                  <Route path="/help" element={<Help />} />
                </Route>
              </Routes>
              {/* </div> */}
            </Layout>
          </BrowserRouter>
          {/* <WSocket2 WSdisconnectionCallback={undefined} /> */}
          <WSocket2 />
          {/* </React.Fragment> */}
        </div>
      </DrawerContext.Provider>
    </ThemeProvider>
  )
}

export default App
