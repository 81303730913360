import { configureStore } from '@reduxjs/toolkit'
import { AnyAction, combineReducers } from 'redux'
import vehiclesSlice from '../redux_features/vehicles/vehicleSlice'
import usersSlice from '../redux_features/drivers/driverSlice'
import sessionSlice from '../redux_features/session/sessionSlice'
import deviceSlice from '../redux_features/devices/deviceSlice'
import geolocationSlice from '../redux_features/geolocations/geolocationSlice'
import histgeolocationSlice from '../redux_features/geolocations/histgeolocationSlice'

import userSlice from '../redux_features/users/userSlice'
import geofenceSlice from '../redux_features/geofences/geofenceSlice'
import devicedataslice from '../redux_features/deviceData/devicedataSlice'
//import { apiSlice } from '../features/apislice/apiSlice'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // You can choose different storage engines based on your platform (localStorage, AsyncStorage, etc.)
import selectedThemeReducer from '../../src/yeyi/redux/slices/ThemeSelector'

const persistConfig = {
  key: 'root', // key is a unique identifier for your storage, could be anything
  storage, // the storage engine you're using
  // Optionally, you can whitelist or blacklist specific reducers:
  // whitelist: ['session', 'vehicles', 'devices', 'session'], // reducers to persist
  blacklist: ['vehicles', 'drivers', 'users', 'devices', 'geofences'], // reducers not to persist
}

const appReducer = combineReducers({
  vehicles: vehiclesSlice,
  drivers: usersSlice,
  users: userSlice,
  session: sessionSlice,
  devices: deviceSlice,
  geolocations: geolocationSlice,
  histgeolocations: histgeolocationSlice,
  geofences: geofenceSlice,
  devicedata: devicedataslice,
  selectedThemeReducer,
})

const rootReducer = (state: any, action: AnyAction) => {
  console.log('rootReducer', action.type)
  if (action.type === 'session/deauthenticate') {
    console.log('trying to reset store')
    localStorage.removeItem('persist:root')
    state = undefined
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
})
let persistor = persistStore(store)

export { persistor }
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
