import { ReactElement, useState } from 'react'

import { MapProps, OrganizationUser, UserSettings } from '../../api/types'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../app/hooks'
import { RootState } from '../../app/store'

import { Status, Wrapper } from '@googlemaps/react-wrapper'

import {
  Button,
  Paper,
  Switch,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
  Container,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import MapIcon from '@mui/icons-material/Map'
import SpeedIcon from '@mui/icons-material/Speed'
import StraightenIcon from '@mui/icons-material/Straighten'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import NotificationsIcon from '@mui/icons-material/Notifications'

import './UserPreferences.css'
import React from 'react'
import { updateUserSetting } from '../../redux_features/session/sessionSlice'

// New component for accordion sections
const SettingAccordion: React.FC<{
  title: string
  expanded: boolean
  onChange: (event: React.SyntheticEvent, isExpanded: boolean) => void
  children: React.ReactNode
  icon: React.ReactElement
}> = ({ title, expanded, onChange, children, icon }) => {
  const theme = useTheme()
  return (
    <Accordion expanded={expanded} onChange={onChange}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {React.cloneElement(icon, {
          sx: {
            color: theme.palette.primary.main,
            mr: 2,
            fontSize: '1.5rem',
          },
        })}
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  )
}

// New component for notification settings
const NotificationSetting: React.FC<{
  label: string
  emailChecked: boolean
  onEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  smsChecked: boolean
  onSmsChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}> = ({ label, emailChecked, onEmailChange, smsChecked, onSmsChange }) => (
  <div>
    <FormControlLabel
      control={<Switch checked={emailChecked} onChange={onEmailChange} />}
      label={`Email ${label}`}
    />
    <FormControlLabel
      control={<Switch checked={smsChecked} onChange={onSmsChange} />}
      label={`SMS ${label}`}
    />
  </div>
)

// New component for unit settings
const UnitSetting: React.FC<{
  label: string
  checked: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  value: string
}> = ({ label, checked, onChange, value }) => (
  <FormControlLabel
    control={<Checkbox checked={checked} onChange={onChange} value={value} />}
    label={label}
  />
)

export type secureUserSettings = {
  userSet: UserSettings
  token: string
  organizationId: string
}

const REACT_APP_GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY

const UserPreferences = () => {
  const [expanded, setExpanded] = useState<string | false>(false)
  const dispatch = useAppDispatch()
  const userSettings = useSelector(
    (state: RootState) =>
      state.session.userdata.OrganizationUser.primaryOrganizationUser
        .userSettings,
  )
  const organizationUser = useSelector(
    (state: RootState) =>
      state.session.userdata.OrganizationUser.primaryOrganizationUser,
  )
  const [emailNotificationsGeofence, setEmailNotificationsGeofence] = useState(
    userSettings?.emailNotificationsGeofence,
  )
  const [emailNotificationsIgnition, setEmailNotificationsIgnition] = useState(
    userSettings?.emailNotificationsIgnition,
  )
  const [emailNotificationsMotion, setEmailNotificationsMotion] = useState(
    userSettings?.emailNotificationsMotion,
  )
  const [smsNotificationsGeofence, setSmsNotificationsGeofence] = useState(
    userSettings?.smsNotificationsGeofence,
  )
  const [smsNotificationsIgnition, setSmsNotificationsIgnition] = useState(
    userSettings?.smsNotificationsIgnition,
  )
  const [smsNotificationsMotion, setSmsNotificationsMotion] = useState(
    userSettings?.smsNotificationsMotion,
  )

  const sessionToken = useSelector(
    (state: RootState) => state.session.userdata.accesstoken,
  )
  const organizationId = useSelector(
    (state: RootState) => state.session.userdata.organizationId,
  )

  const map_styles = ['default', 'silver', 'retro', 'night', 'hiding']

  const [distanceUnit, setDistanceunits] = useState(userSettings?.distanceUnit)
  const [distanceUnitCheck, setDistanceunitCheck] = useState(
    () => userSettings?.distanceUnit === 'km',
  )
  const [speedUnit, setspeedunits] = useState(userSettings?.speedUnit)
  const [speedUnitCheck, setspeedUnitCheck] = useState(
    () => userSettings?.speedUnit === 'kph',
  )
  const [timezone, setTimezone] = useState(userSettings?.timezone)
  const [mapOverlay, setMapOverlay] = useState(userSettings?.mapOverlay)

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  // const handleMaptypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setMapOverlay(
  //     e.target.value as 'silver' | 'default' | 'retro' | 'night' | 'hiding',
  //   )
  // }

  const DistanceUnitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isKm = e.target.value === 'km'
    setDistanceunitCheck(isKm)
    setDistanceunits(isKm ? 'km' : 'mi')
  }

  const SpeedUnitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isKm = e.target.value === 'km'
    setspeedUnitCheck(isKm)
    setspeedunits(isKm ? 'kph' : 'mph')
  }

  const UpdateSettings = () => {
    const updatedUserSettings: UserSettings = {
      distanceUnit: distanceUnit || 'mi',
      speedUnit: speedUnit || 'mph',
      mapOverlay: mapOverlay || 'default',
      timezone: timezone || 'America/New_York',
      emailNotificationsGeofence,
      emailNotificationsIgnition,
      emailNotificationsMotion,
      smsNotificationsGeofence,
      smsNotificationsIgnition,
      smsNotificationsMotion,
      organizationUser: organizationUser as OrganizationUser,
      id: 0,
      createdAt: new Date(),
      lastModified: new Date(),
      deletedAt: new Date(),
    }

    dispatch(
      updateUserSetting({
        userData: {
          ...organizationUser,
          nickname: organizationUser?.nickname || '',
          userSettings: updatedUserSettings,
        },
        token: sessionToken,
        organizationId: organizationId,
      }),
    )
  }

  const onTimezoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTimezone(e.target.value)
  }

  const mapProps: MapProps = {
    center: { lat: 26.25979, lng: -80.08094 },
    zoom: 8,
    height: '300px',
    width: '100%',
    cssstyle: 'map2',
    mapstyle: mapOverlay!,
    enable_controller: false,
  }

  const render = (status: Status): ReactElement => {
    return <h1>{status}</h1>
  }

  const notificationSettings = [
    {
      label: 'Geofence',
      email: emailNotificationsGeofence,
      setEmail: setEmailNotificationsGeofence,
      sms: smsNotificationsGeofence,
      setSms: setSmsNotificationsGeofence,
    },
    {
      label: 'Ignition',
      email: emailNotificationsIgnition,
      setEmail: setEmailNotificationsIgnition,
      sms: smsNotificationsIgnition,
      setSms: setSmsNotificationsIgnition,
    },
    {
      label: 'Motion',
      email: emailNotificationsMotion,
      setEmail: setEmailNotificationsMotion,
      sms: smsNotificationsMotion,
      setSms: setSmsNotificationsMotion,
    },
  ]

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>
        User Settings
      </Typography>
      <div className="UserPreferences--SettingsContainer">
        <SettingAccordion
          title="Map Style"
          expanded={expanded === 'setting4'}
          onChange={handleAccordionChange('setting4')}
          icon={<MapIcon />}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <RadioGroup value={mapOverlay}>
                {map_styles.map((item) => (
                  <FormControlLabel
                    key={item}
                    value={item}
                    control={<Radio />}
                    label={item}
                  />
                ))}
              </RadioGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper sx={{ height: 300, overflow: 'hidden' }}>
                <Wrapper apiKey={REACT_APP_GOOGLE_API_KEY!} render={render}>
                  map here
                  {/*   <MainMap {...mapProps}>map</MainMap> */}
                </Wrapper>
              </Paper>
            </Grid>
          </Grid>
        </SettingAccordion>

        <SettingAccordion
          title="Speed Units"
          expanded={expanded === 'setting1'}
          onChange={handleAccordionChange('setting1')}
          icon={<SpeedIcon />}
        >
          <FormGroup>
            <UnitSetting
              label="Mile"
              checked={!distanceUnitCheck}
              onChange={DistanceUnitChange}
              value="mile"
            />
            <UnitSetting
              label="Kilometer"
              checked={distanceUnitCheck}
              onChange={DistanceUnitChange}
              value="km"
            />
          </FormGroup>
        </SettingAccordion>

        <SettingAccordion
          title="Distance Units"
          expanded={expanded === 'setting2'}
          onChange={handleAccordionChange('setting2')}
          icon={<StraightenIcon />}
        >
          <FormGroup>
            <UnitSetting
              label="Mile"
              checked={!speedUnitCheck}
              onChange={SpeedUnitChange}
              value="mile"
            />
            <UnitSetting
              label="Kilometer"
              checked={speedUnitCheck}
              onChange={SpeedUnitChange}
              value="km"
            />
          </FormGroup>
        </SettingAccordion>

        <SettingAccordion
          title="Time Zone"
          expanded={expanded === 'setting3'}
          onChange={handleAccordionChange('setting3')}
          icon={<AccessTimeIcon />}
        >
          <Typography>{timezone}</Typography>
        </SettingAccordion>

        <SettingAccordion
          title="Notification Preferences"
          expanded={expanded === 'setting5'}
          onChange={handleAccordionChange('setting5')}
          icon={<NotificationsIcon />}
        >
          {notificationSettings.map((setting, index) => (
            <NotificationSetting
              key={index}
              label={setting.label}
              emailChecked={setting.email ?? false}
              onEmailChange={() => setting.setEmail(!setting.email)}
              smsChecked={setting.sms ?? false}
              onSmsChange={() => setting.setSms(!setting.sms)}
            />
          ))}
        </SettingAccordion>
      </div>

      <div className="UserPreferences--ActionButtonsContainer">
        <Button
          variant="contained"
          color="primary"
          onClick={UpdateSettings}
          style={{ marginRight: 10 }}
        >
          Save
        </Button>
        <Button
          variant="outlined"
          onClick={() => alert('Your changes have been disregarded')}
        >
          Cancel
        </Button>
      </div>
    </Container>
  )
}

export default UserPreferences
