import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { CognitoUserSession, CognitoUser } from 'amazon-cognito-identity-js'
import { OrganizationUser, RdxStateSession } from '../../api/types'
import { getSession } from './cogAccount'

import { updateItem } from '../../api/api_functions'

let emptydata: RdxStateSession = {
  username: '',
  accesstoken: '',
  idtoken: {},
  OrganizationUser: {
    primaryOrganizationUser: {
      nickname: '',
      user: {
        name: '',
        email: '',
        enrolled: false,
        id: '',
        createdAt: new Date(),
        lastModified: new Date(),
        deletedAt: new Date(),
      },
    },
  },
  organizationId: '',
  roles: [],
}

let initialState = {
  userdata: emptydata,
  status: 'idle',
  settingsStatus: 'idle',
  error: null as string | null,
}

export const fetchsessionthunk = createAsyncThunk(
  'session/fetchSession',

  async () => {
    let sessionUserdata: RdxStateSession = emptydata

    await getSession()
      .then((data: any) => {
        const user: CognitoUser = data['user']

        const session: CognitoUserSession = data['session']

        const idToken = session.getIdToken().decodePayload()

        const userinfo: any = JSON.parse(idToken['users'])

        sessionUserdata = {
          coguser: user,
          username: data['user']['username'],
          accesstoken: session.getAccessToken().getJwtToken(),
          idtoken: idToken,
          OrganizationUser: userinfo,
          profile: data['profile'],
          organizationId: localStorage.getItem('organizationId')!,
          roles: ['admin'], //TODO review
        }
      })
      .catch((e: any) => {
        console.log('error', e)
      })

    return sessionUserdata
  },
)

export const updateUserSetting = createAsyncThunk(
  'session/updateUserSettings',
  async ({
    userData,
    token,
    organizationId,
  }: {
    userData: Partial<OrganizationUser>
    token: string
    organizationId: string
  }) => {
    const userSettings = await updateItem(
      'users',
      userData,
      token,
      organizationId,
    )
    return userSettings
  },
)

const sessionSlice = createSlice({
  name: 'session',
  initialState: initialState,
  reducers: {
    setCredentials: (state, action) => {
      const userdata = action.payload
      state.userdata = userdata
      state.status = 'succeeded'
    },

    deauthenticate: (state, action) => {
      //action.type is equal to the reducer name in this case sesssion/deauthenticate
      state.userdata = emptydata
      state.status = 'idle'
    },
    setOrganizationId: (state, action: PayloadAction<string>) => {
      state.userdata.organizationId = action.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchsessionthunk.pending, (state, action: PayloadAction) => {
        state.status = 'loading'
      })
      .addCase(fetchsessionthunk.fulfilled, (state, action) => {
        state.status = 'succeeded'

        state.userdata = action.payload
      })
      .addCase(fetchsessionthunk.rejected, (state, action) => {
        state.status = 'failed'

        //
      })
      .addCase(updateUserSetting.pending, (state) => {
        state.settingsStatus = 'loading'
      })
      .addCase(updateUserSetting.fulfilled, (state, action) => {
        state.settingsStatus = 'succeeded'
        state.userdata.OrganizationUser.primaryOrganizationUser = {
          ...state.userdata.OrganizationUser.primaryOrganizationUser,
          ...action.payload,
        }
      })
      .addCase(updateUserSetting.rejected, (state, action) => {
        state.settingsStatus = 'failed'
        state.error = action.error.message || 'Failed to update user settings'
      })
  },
})

export const { setCredentials, deauthenticate, setOrganizationId } =
  sessionSlice.actions

export default sessionSlice.reducer

export const selectSession = (state: any) => state.session
