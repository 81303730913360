import axios, { AxiosResponse } from 'axios'

import { AuthError } from '../redux_features/session/cogAccount'
import { OrganizationUser } from './types'

const API_BASE_URL = process.env.REACT_APP_API_URL

export interface UserClaims {
  primaryOrganizationUser: OrganizationUser
}

export interface ClaimsResult {
  success: boolean
  userInfo?: { primaryOrganizationUser: OrganizationUser } //back to userClaims
  registrationComplete?: boolean
  error?: AuthError
}

export const getAuthenticatedUser = async (
  email: string,
  accessToken: string,
): Promise<ClaimsResult> => {
  try {
    const response: AxiosResponse<any> = await axios.post(
      `${API_BASE_URL}/get-authenticated-user`,
      { email },
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    )

    if (response.data.success) {
      return {
        success: true,
        userInfo: response.data.userInfo,
        registrationComplete: response.data.registrationComplete,
      }
    } else {
      return {
        success: false,
        error: {
          code: 'auth_failed',
          message: response.data.error || 'Failed to authenticate user',
        },
      }
    }
  } catch (error) {
    console.error('Error fetching authenticated user:', error)
    return {
      success: false,
      error: {
        code: 'unknown_error',
        message:
          error instanceof Error ? error.message : 'An unknown error occurred',
      },
    }
  }
}
